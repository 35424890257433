import * as React from "react"
import { useState } from "react"
import PropTypes from "prop-types"
import Link from "./Link"
import logo from "../images/NewburyLogo.svg"
import Headroom from "react-headroom"
import { MenuEn } from "../data/menuEn"
import MenuItem from "./MenuItem"
import InvestorMenu from "./InvestorMenu"
import { Squash as Hamburger } from "hamburger-react"

import InvestorMenuMobile from "./InvestorMenuMobile"

export default function Header({ isHome, isInvestor, pageTitle }) {
  const [isExpanded, toggleExpansion] = useState(false)
  return (
    <div>
      <Headroom>
        <header
          className={`${
            isHome ? "" : "border-b-8 border-primary"
          } bg-white   py-4 z-10 relative`}
        >
          <div className="flex flex-row items-center justify-between pl-4 md:px-24">
            <Link to="/">
              <div className="w-48">
                <img src={logo} alt="logo" />
              </div>
            </Link>
            <div className="hidden lg:flex flex-row items-center justify-between space-x-8 whitespace-nowrap text-xl ">
              {MenuEn.items.map(menuItem => {
                return <MenuItem key={menuItem.title} menuItem={menuItem} />
              })}
            </div>
            <div className=" inline-block lg:hidden">
              <Hamburger
                toggled={isExpanded}
                toggle={toggleExpansion}
                onClick={() => toggleExpansion(!isExpanded)}
                size="24"
                className="z-50 relative"
                style={{ padding: "0", margin: "0" }}
              />
            </div>
          </div>
        </header>
        {!isHome && !isInvestor && (
          <div className="teal-border border-b-4 border-secondary z-20 relative"></div>
        )}
        {isInvestor && <InvestorMenu />}
        {isInvestor && <InvestorMenuMobile pageTitle={pageTitle} />}
        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } fixed top-0 inset-x-0 transition transform origin-top-right lg:hidden `}
        >
          <div className="bg-white min-h-screen mobilemenu text-2xl pt-2">
            <div
              className="flex items-center justify-between px-4 h-full"
              style={{ paddingTop: "10px" }}
            >
              <Link to="/">
                <div className="w-48">
                  <img src={logo} alt="logo" />
                </div>
              </Link>
            </div>
            <div className="pt-2 pb-6 px-5 ">
              <div className="mt-6 flex flex-col justify-between h-full">
                <div>
                  <div className="space-y-4 mt-4">
                    {MenuEn.itemsMobile.map(menuItem => {
                      return (
                        <Link to={menuItem.route} className="block text-black">
                          {menuItem.title}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Headroom>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

function InvestorMenuItem({ menuItem }) {
  const { route, title } = menuItem
  return (
    <div className="whitespace-nowrap">
      <Link
        to={route}
        className="text-white mx-4 hover:text-gray-700"
        activeClassName="text-gray-700"
      >
        {title}
      </Link>
    </div>
  )
}
