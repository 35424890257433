import React from "react"
import Link from "./Link"

export default function Button({ link, text }) {
  return (
    <div className="">
      <Link
        to={link}
        className="bg-gradient-to-r from-primaryfaded to-primary text-white py-4 px-6 rounded-full text-xl transition duration-150 ease-in-out transform hover:scale-110 inline-block"
      >
        {text}
      </Link>
    </div>
  )
}
