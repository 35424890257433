import React from "react"
import { MenuEn } from "../data/menuEn"
import Link from "./Link"
export default function InvestorMenu() {
  return (
    <div className="teal-border flex-row items-center flex-wrap justify-end md:pr-24 text-base bg-secondary py-4 hidden lg:flex space-x-8">
      {MenuEn.investorItems.map(menuItem => {
        return <InvestorMenuItem menuItem={menuItem} key={menuItem.route} />
      })}
    </div>
  )
}
function InvestorMenuItem({ menuItem }) {
  const { route, title } = menuItem
  return (
    <div className="whitespace-nowrap">
      <Link
        to={route}
        className="text-white hover:text-gray-700 whitespace-nowrap"
        activeClassName="text-gray-700"
      >
        {title}
      </Link>
    </div>
  )
}
