/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent"
import Header from "./header"
import Hero from "./Hero"
import Footer from "./Footer"
import Quotes from "./Quotes"

const Layout = ({ children, isHome, isInvestor, pageTitle }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="text-black text-xl">
      {isHome ? (
        <div className="">
          <div className="flex flex-col h-screen">
            <div className="flex-none">
              <Header
                isHome
                siteTitle={data.site.siteMetadata?.title || `Title`}
              />
            </div>
            <div className="h-full w-full overflow-hidden">
              <div>
                <Quotes />
              </div>
            </div>
          </div>

          <main className="">{children}</main>
          <div id="contact">
            <Footer />
          </div>
        </div>
      ) : (
        <div>
          {" "}
          <Header
            isHome={false}
            isInvestor={isInvestor}
            pageTitle={pageTitle}
            siteTitle={data.site.siteMetadata?.title || `Title`}
          />
          <main>{children}</main>
        </div>
      )}
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: "#FFFFFF", color: "#000000" }}
        buttonStyle={{
          backgroundColor: "#FF823C",
          color: "#FFFFFF",
          fontSize: "16px",
          padding: "10px 15px",
          borderRadius: "99999px",
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
