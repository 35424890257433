import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import loadable from "@loadable/component"
const Carousel = loadable(() => import("fade-carousel"))

export default function Quotes() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 346 }) {
        quotes {
          carouselQuotes {
            person
            quote
          }
        }
      }
    }
  `)
  const quotes = data.wpPage.quotes.carouselQuotes
  return (
    <div className="h-screen relative bg-black ">
      <StaticImage
        src="../images/bild2.jpg"
        alt="Quote"
        layout="constrained"
        className="object-cover h-full opacity-75"
      />
      <div className="absolute quote  text-white h-full w-full top-0 left-0 flex justify-center items-center text-center">
        <Carousel>
          {quotes.map(quote => {
            return <Quote key={quote.person} quote={quote} />
          })}
        </Carousel>
      </div>
    </div>
  )
}

function Quote({ quote }) {
  return (
    <div className="relative -top-12 md:-top-6 h-full w-full text-center flex items-center justify-center">
      <div className="max-w-3xl mx-auto space-y-8 px-8">
        <div className="text-3xl md:text-5xl font-bold">{quote.quote}</div>
        <div className="text-xl">{quote.person}</div>
      </div>
    </div>
  )
}
