import React from "react"
import { MenuEn } from "../data/menuEn"
import Link from "./Link"
import { Fragment, useState, useEffect } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ArrowDownIcon } from "@heroicons/react/solid"
import { navigate } from "gatsby-link"

export default function InvestorMenuMobile({ pageTitle }) {
  const [selected, setSelected] = useState(MenuEn.investorItems[0])
  console.log(selected.title)
  const handleChange = selected => {
    setSelected(selected)
    navigate(selected.route)
  }
  console.log(pageTitle)
  return (
    <div className="block md:hidden">
      <Listbox value={selected} onChange={handleChange}>
        <div className="relative ">
          <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-secondary text-white rounded-sm cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500">
            <span className="block truncate">{pageTitle}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ArrowDownIcon
                className="w-5 h-5 text-white"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              as="div"
              className="absolute w-full py-1 overflow-auto  bg-secondary   ring-1 ring-black ring-opacity-5 focus:outline-none "
            >
              {MenuEn.investorItems.map((item, i) => (
                <Listbox.Option
                  as="div"
                  key={i}
                  className={({ active }) =>
                    `${active ? "" : "text-white"}
                          cursor-default select-none relative  pl-4 pr-4 `
                  }
                  value={item}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? "font-medium" : "font-normal"
                        } block truncate cursor-pointer py-2`}
                      >
                        {item.title}
                      </span>
                      {selected ? (
                        <span
                          className={`${active ? "" : ""}
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                        ></span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
