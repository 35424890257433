import React, { useState, useRef } from "react"
import { Menu, Transition } from "@headlessui/react"
import Link from "./Link"

import { useDetectOutsideClick } from "../hooks/useDetectOutsideClick"

export default function MenuItem({ menuItem }) {
  const dropdownRef = useRef(null)
  const [openDropdown, setOpenDropdown] = useDetectOutsideClick(
    dropdownRef,
    false
  )
  const [mouseOverButton, setMouseOverButton] = useState(false)
  const [mouseOverMenu, setMouseOverMenu] = useState(false)
  const timeoutDuration = 200
  let timeoutButton
  let timeoutMenu

  const onMouseEnterButton = () => {
    clearTimeout(timeoutButton)
    setOpenDropdown(true)
    setMouseOverButton(true)
  }
  const onMouseLeaveButton = () => {
    timeoutButton = setTimeout(() => setMouseOverButton(false), timeoutDuration)
  }

  const onMouseEnterMenu = () => {
    clearTimeout(timeoutMenu)
    setMouseOverMenu(true)
  }
  const onMouseLeaveMenu = () => {
    timeoutMenu = setTimeout(() => setMouseOverMenu(false), timeoutDuration)
  }

  const show = openDropdown && (mouseOverMenu || mouseOverButton)
  return (
    <div className="">
      {menuItem.subMenu === null ? (
        <Link
          className="px-4 text-black hover:text-gray-500 transition-all"
          activeClassName="text-gray-500"
          to={menuItem.route}
          title={menuItem.title}
        >
          <div>{menuItem.title}</div>
        </Link>
      ) : (
        <Menu>
          <div
            onMouseEnter={onMouseEnterButton}
            onMouseLeave={onMouseLeaveButton}
            onKeyPress={null}
            role="button"
            tabIndex="0"
          >
            <Menu.Button>
              <div
                className={`${show ? "" : ""} cursor-pointer  `}
                onTouchStart={() => setOpenDropdown(!openDropdown)}
              >
                {menuItem.title}
              </div>
            </Menu.Button>
          </div>
          <div className="absolute ">
            <Transition show={show}>
              <Menu.Items
                ref={dropdownRef}
                onMouseEnter={onMouseEnterMenu}
                onMouseLeave={onMouseLeaveMenu}
                static
                className="bg-white  z-10 px-4 menu-items"
              >
                <div className="pl-8 -ml-8">
                  <div className="relative  py-6">
                    <nav className="space-y-6 ">
                      {menuItem.subMenu?.map(subMenuItem => {
                        return (
                          <Menu.Item
                            as="div"
                            onClick={() => setOpenDropdown(false)}
                          >
                            <div className="flex flex-row items-center">
                              <div>
                                <Link
                                  className="-m-3 px-3 text-black"
                                  to={subMenuItem.route}
                                  title={subMenuItem.title}
                                >
                                  {subMenuItem.title}
                                </Link>
                              </div>
                            </div>
                          </Menu.Item>
                        )
                      })}
                    </nav>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </Menu>
      )}
    </div>
  )
}
