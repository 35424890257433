export const MenuEn = {
  name: "Main Menu",
  items: [
    {
      title: "About us",
      route: "/about/",
      subMenu: null,
    },
    // {
    //   title: "IPO",
    //   route: "/investors/ipo",
    //   subMenu: null,
    // },
    {
      title: "Portfolio",
      route: "/portfolio/",
      subMenu: null,
    },

    {
      title: "Contact",
      route: "/contact/",
      subMenu: null,
    },

    {
      title: "News",
      route: "/news/",
      subMenu: null,
    },

    {
      title: "Career",
      route: "/career/",
      subMenu: null,
    },
    {
      title: "Partnering",
      route: "/partnering/",
      subMenu: null,
    },
    {
      title: "Investors",
      route: "/investors/",
      subMenu: null,
    },
  ],
  itemsMobile: [
    {
      title: "About us",
      route: "/about/",
      subMenu: null,
    },
    // {
    //   title: "IPO",
    //   route: "/investors/ipo",
    //   subMenu: null,
    // },
    {
      title: "Portfolio",
      route: "/portfolio/",
      subMenu: null,
    },

    {
      title: "Contact",
      route: "/contact/",
      subMenu: null,
    },

    {
      title: "News",
      route: "/news/",
      subMenu: null,
    },

    {
      title: "Career",
      route: "/career/",
      subMenu: null,
    },
    {
      title: "Partnering",
      route: "/partnering/",
      subMenu: null,
    },
    {
      title: "Investors",
      route: "/investors/",
      subMenu: null,
    },
  ],
  investorItems: [
    {
      id: 1,
      title: "Investor Relations",
      route: "/investors/",
      subMenu: null,
    },
    {
      id: 2,
      title: "IPO",
      route: "/investors/ipo",
      subMenu: null,
    },
    {
      id: 3,
      title: "Financial Calendar",
      route: "/investors/financial-calendar",
      subMenu: null,
    },
    {
      id: 4,
      title: "Press Releases",
      route: "/investors/press-releases",
      subMenu: null,
    },
    {
      id: 5,
      title: "Corporate Governance",
      route: "/investors/corporate-governance",
      subMenu: null,
    },
    // {
    //   id: 6,
    //   title: "Shareholders",
    //   route: "/investors/shareholders",
    //   subMenu: null,
    // },
    {
      id: 7,
      title: "Financial Reports",
      route: "/investors/financial-information/",
      subMenu: null,
    },
    {
      id: 7,
      title: "Stock Information",
      route: "/investors/stock-information/",
      subMenu: null,
    },
  ],
}
