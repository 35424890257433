import React from "react"

import img from "../images/NewburyLogoWhite.svg"
import Link from "./Link"
import { useStaticQuery, graphql } from "gatsby"

export default function Footer() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 346 }) {
        id
        privacyPolicy {
          fileUpload {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)
  const privacyDownload =
    data.wpPage.privacyPolicy.fileUpload.localFile?.publicURL

  return (
    <div>
      <div className="bg-primary h-screen/2 px-4 md:px-24 py-8 text-base">
        <div className="grid md:grid-cols-2 gap-16 text-white">
          <div className="">
            <div className="w-48">
              <img src={img} alt="logo" />
            </div>
          </div>
          <div className=" space-y-4">
            <p className=" text-white ">© 2021, Newbury Pharmaceuticals AB</p>
            <p className="">
              <a href="mailto:info@newburypharma.com" className="text-white">
                info&#64;newburypharma.com
              </a>
            </p>
            <p>
              <Link to="/news" className="text-white">
                Subscribe to our news feed
              </Link>
            </p>
            <p className=" ">
              <Link
                className=" text-white"
                to="https://www.linkedin.com/company/newbury-pharmaceuticals/?originalSubdomain=se"
              >
                Join us on Linkedin
              </Link>
            </p>
            <p className=" ">
              <Link
                className=" text-white"
                to="http://cms.newburypharma.com/wp-content/uploads/2022/06/Supplier_code_of_conduct_NB_final_v2.pdf"
              >
                Supplier Code
              </Link>
            </p>
            <p className=" ">
              <Link className=" text-white" to={privacyDownload}>
                View our privacy policy
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
